import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import MicroModal from "micromodal";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Contact from "../Form/Contact";

const StyledModal = styled.div`
  .modal {
    &[aria-hidden="false"] {
      .overlay {
        animation: mmfadeIn 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="false"] {
      .content-wrapper {
        animation: mmslideIn 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .overlay {
        animation: mmfadeOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .content-wrapper {
        animation: mmslideOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
`;

const StyledTabs = styled.div`
  .react-tabs__tab-list {
    ${tw`grid grid-cols-2 mb-10 md:mb-14 border-none`}
  }
  .react-tabs__tab {
    ${tw`text-sm text-gray-900/40 font-semibold py-2 px-4 bg-gray-50 rounded-none flex items-center justify-center w-full border-none transition-colors duration-300 ease-linear`}
  }
  .react-tabs__tab--selected {
    ${tw`text-white bg-primary-600`}
    svg {
      path {
        ${tw`text-white`}
      }
    }
  }
`;

const Modal = () => {
  if (typeof window !== "undefined") {
    MicroModal.init({
      openTrigger: "data-modal-open",
      closeTrigger: "data-modal-close",
      disableFocus: true,
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });
  }

  const data = useStaticQuery(graphql`
    {
      broomfield: file(relativePath: { eq: "modal/broomfield.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 160)
        }
      }
      denver: file(relativePath: { eq: "modal/denver.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 160)
        }
      }
      longmont: file(relativePath: { eq: "modal/longmont.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 160)
        }
      }
      loveland: file(relativePath: { eq: "modal/loveland.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 160)
        }
      }
    }
  `);

  return (
    <StyledModal>
      <div
        className="modal relative hidden z-50"
        id="modal-contact"
        aria-hidden="false"
      >
        <div
          className="overlay fixed flex items-center justify-center top-0 right-0 left-0 bottom-0 bg-black outline-none"
          tabIndex="-1"
          data-modal-close
        >
          <div
            className="content-wrapper bg-white w-full h-screen overflow-auto max-w-[720px] ml-auto"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex justify-between items-center pt-12 pb-4 md:py-4 px-5 border-b border-gray-100">
              <a
                href="tel:720-491-3117"
                className="text-primary-600 hover:text-primary-800 font-bold"
              >
                <i className="fas fa-phone-alt mr-1"></i> (720) 491-3117
              </a>
              <i
                className="close fal fa-times text-xl text-black hover:text-primary-600 cursor-pointer transition-all duration-300 ease-linear"
                data-modal-close
              ></i>
            </div>

            <div className="pt-8 px-4 md:px-18 pb-24">
              <header className="mb-6 md:mb-8 text-center">
                <p className="heading-three text-[#26272B]">Contact Us</p>
              </header>

              <StyledTabs>
                <Tabs>
                  <TabList>
                    <Tab>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-1.5"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.33407 2.5H16.6674C18.04 2.5 19.1604 3.61606 19.1674 4.98704C19.1675 4.99435 19.1675 5.00167 19.1674 5.00897V15C19.1674 16.3769 18.0443 17.5 16.6674 17.5H3.33407C1.95717 17.5 0.834073 16.3769 0.834073 15V5.01143C0.83394 5.00231 0.833957 4.99318 0.834123 4.98405C0.842756 3.61443 1.96249 2.5 3.33407 2.5ZM2.50074 6.60056V15C2.50074 15.4564 2.87764 15.8333 3.33407 15.8333H16.6674C17.1238 15.8333 17.5007 15.4564 17.5007 15V6.60053L10.4786 11.516C10.1917 11.7169 9.80977 11.7169 9.52284 11.516L2.50074 6.60056ZM2.58955 4.6283L10.0007 9.81612L17.4119 4.62828C17.2741 4.35577 16.9907 4.16667 16.6674 4.16667H3.33407C3.01077 4.16667 2.72737 4.35577 2.58955 4.6283Z"
                          className="fill-current text-gray-900/40 transition-colors duration-300 ease-linear"
                        />
                        <mask
                          id="mask0_673_25505"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="2"
                          width="20"
                          height="16"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.33407 2.5H16.6674C18.04 2.5 19.1604 3.61606 19.1674 4.98704C19.1675 4.99435 19.1675 5.00167 19.1674 5.00897V15C19.1674 16.3769 18.0443 17.5 16.6674 17.5H3.33407C1.95717 17.5 0.834073 16.3769 0.834073 15V5.01143C0.83394 5.00231 0.833957 4.99318 0.834123 4.98405C0.842756 3.61443 1.96249 2.5 3.33407 2.5ZM2.50074 6.60056V15C2.50074 15.4564 2.87764 15.8333 3.33407 15.8333H16.6674C17.1238 15.8333 17.5007 15.4564 17.5007 15V6.60053L10.4786 11.516C10.1917 11.7169 9.80977 11.7169 9.52284 11.516L2.50074 6.60056ZM2.58955 4.6283L10.0007 9.81612L17.4119 4.62828C17.2741 4.35577 16.9907 4.16667 16.6674 4.16667H3.33407C3.01077 4.16667 2.72737 4.35577 2.58955 4.6283Z"
                            className="fill-current text-gray-900/40 transition-colors duration-300 ease-linear"
                          />
                        </mask>
                      </svg>
                      Get in Touch
                    </Tab>
                    <Tab>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-1.5"
                      >
                        <g clipPath="url(#clip0_673_25524)">
                          <path
                            d="M17.5 8.33331C17.5 14.1666 10 19.1666 10 19.1666C10 19.1666 2.5 14.1666 2.5 8.33331C2.5 6.34419 3.29018 4.43653 4.6967 3.03001C6.10322 1.62349 8.01088 0.833313 10 0.833313C11.9891 0.833313 13.8968 1.62349 15.3033 3.03001C16.7098 4.43653 17.5 6.34419 17.5 8.33331Z"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="stroke-current text-gray-900/40 transition-colors duration-300 ease-linear"
                          />
                          <path
                            d="M10 10.8333C11.3807 10.8333 12.5 9.71403 12.5 8.33331C12.5 6.9526 11.3807 5.83331 10 5.83331C8.61929 5.83331 7.5 6.9526 7.5 8.33331C7.5 9.71403 8.61929 10.8333 10 10.8333Z"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="stroke-current text-gray-900/40 transition-colors duration-300 ease-linear"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_673_25524">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Find a Location
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <Contact />
                  </TabPanel>

                  <TabPanel>
                    <AniLink fade to="/broomfield/" className="group">
                      <div className="inline-flex flex-row space-x-6 items-center mb-10">
                        <div className="overflow-hidden">
                          <GatsbyImage
                            image={
                              data.broomfield.childImageSharp.gatsbyImageData
                            }
                            className="transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                          />
                        </div>

                        <div>
                          <div className="font-heading text-lg text-[#26272B] font-extrabold mb-1.5">
                            Broomfield Office
                          </div>
                          <address className="not-italic">
                            <a
                              href="https://goo.gl/maps/4xirs8pC3yyd7qNF7"
                              target="_blank"
                              rel="noreferrer"
                              className="text-gray-600 hover:text-primary-600 text-sm block  font-medium my-1.5"
                            >
                              8001 Arista Pl. Suite 415
                              <br /> Broomfield, CO 80021
                            </a>
                          </address>
                          <a
                            href="tel:720-499-1842"
                            className="text-gray-600 hover:text-primary-600 text-sm block  font-medium my-1.5"
                          >
                            (720) 499-1842
                          </a>
                        </div>
                      </div>
                    </AniLink>
                    <AniLink fade to="/denver/" className="group">
                      <div className="inline-flex flex-row space-x-6 items-center mb-10">
                        <div className="overflow-hidden">
                          <GatsbyImage
                            image={data.denver.childImageSharp.gatsbyImageData}
                            className="transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                          />
                        </div>

                        <div>
                          <div className="font-heading text-lg text-[#26272B] font-extrabold mb-1.5">
                            Denver Office
                          </div>
                          <address className="not-italic">
                            <a
                              href="https://goo.gl/maps/gi3UzsULxwZeKEso7"
                              target="_blank"
                              rel="noreferrer"
                              className="text-gray-600 hover:text-primary-600 text-sm block  font-medium my-1.5"
                            >
                              3461 Ringsby Ct. Suite 350
                              <br /> Denver, CO 80216
                            </a>
                          </address>
                          <a
                            href="tel:(303) 678-0560"
                            className="text-gray-600 hover:text-primary-600 text-sm block  font-medium my-1.5"
                          >
                            (303) 678-0560
                          </a>
                        </div>
                      </div>
                    </AniLink>
                    <AniLink fade to="/longmont/" className="group">
                      <div className="inline-flex flex-row space-x-6 items-center mb-10">
                        <div className="overflow-hidden">
                          <GatsbyImage
                            image={
                              data.longmont.childImageSharp.gatsbyImageData
                            }
                            className="transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                          />
                        </div>

                        <div>
                          <div className="font-heading text-lg text-[#26272B] font-extrabold mb-1.5">
                            Longmont Office
                          </div>
                          <address className="not-italic">
                            <a
                              href="https://goo.gl/maps/DxZwHWW6xLyDTrDD9"
                              target="_blank"
                              rel="noreferrer"
                              className="text-gray-600 hover:text-primary-600 text-sm block  font-medium my-1.5"
                            >
                              900 S Main St. Suite 100
                              <br/> Longmont, CO 80501
                            </a>
                          </address>
                          <a
                            href="tel:720-491-3117"
                            className="text-gray-600 hover:text-primary-600 text-sm block  font-medium my-1.5"
                          >
                            (720) 491-3117
                          </a>
                        </div>
                      </div>
                    </AniLink>
                    <AniLink fade to="/loveland/" className="group">
                      <div className="inline-flex flex-row space-x-6 items-center mb-10">
                        <div className="overflow-hidden">
                          <GatsbyImage
                            image={
                              data.loveland.childImageSharp.gatsbyImageData
                            }
                            className="transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                          />
                        </div>

                        <div>
                          <div className="font-heading text-lg text-[#26272B] font-extrabold mb-1.5">
                            Loveland Office
                          </div>
                          <address className="not-italic">
                            <a
                              href="https://goo.gl/maps/DxZwHWW6xLyDTrDD9"
                              target="_blank"
                              rel="noreferrer"
                              className="text-gray-600 hover:text-primary-600 text-sm block  font-medium my-1.5"
                            >
                              5285 McWhinney Blvd. Suite 100
                              <br /> Loveland, CO 80538
                            </a>
                          </address>
                          <a
                            href="tel:720-491-3117"
                            className="text-gray-600 hover:text-primary-600 text-sm block  font-medium my-1.5"
                          >
                            (970) 644-6275
                          </a>
                        </div>
                      </div>
                    </AniLink>
                  </TabPanel>
                </Tabs>
              </StyledTabs>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
};
export default Modal;
