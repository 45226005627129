import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Button = ({ className, href, outboundLink, modal, onClick, type, altStyle, icon, text }) => {
   const Tag = href ? AniLink : "button";
   const target = outboundLink && "_blank";
   const rel = outboundLink && "noopener noreferrer";
   let link = true;

   href && (link = !href.includes("tel:") && !href.includes("mailto:"));

   return (
      <Tag
         className={`group relative overflow-hidden min-w-[184px] h-[51px] px-6 inline-flex items-center justify-center text-center font-body font-semibold no-underline whitespace-nowrap ${
            altStyle === 2
               ? "bg-white text-black hover:text-black"
               : altStyle === 3
               ? "bg-primary-600 hover:bg-primary-600 border border-white text-white hover:text-white"
               : altStyle === 4
               ? "bg-black hover:bg-black text-white hover:text-white"
               : "bg-primary-600 hover:bg-primary-600 text-white hover:text-white"
         } ${className || ""}`}
         {...(link ? { fade: "true", to: href } : { href: href })}
         target={target}
         rel={rel}
         data-modal-open={modal}
         onClick={onClick}
         type={type}
         alt-style={altStyle}
      >
         {icon && (
            <i
               className={`mr-2 relative left-0 group-hover:left-2 transition-all duration-300 ease-linear z-10 ${
                  altStyle ? "text-white" : "text-white"
               } ${icon}`}
            ></i>
         )}
         <span className="text block opacity-100 group-hover:opacity-0 transform translate-y-0 group-hover:translate-y-[-51px] transition-all duration-300 ease-linear">
            {text}
         </span>
         <span className="text-hover block absolute opacity-0 group-hover:opacity-100 transform translate-y-[51px] group-hover:translate-y-0 transition-all duration-300 ease-linear">
            {text}
         </span>
      </Tag>
   );
};

export default Button;
