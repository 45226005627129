import React, { useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useOnClickOutside } from "../../hooks";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";

import Burger from "./Burger";
import OffCanvas from "../OffCanvas/OffCanvas";
import ButtonSolid from "../Button/ButtonSolid";
import ButtonWithIcon from "../Button/ButtonWithIcon";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const MainNav = ({
  headerStyle,
  headerHasBorder,
  // headerLinkColor,
  scrolled,
}) => {
  // determine if offcanvas is open
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);

  const [subMenuHovering1, setSubMenuHovering1] = useState(false);
  const isHoveringSubMenu1 = () => setSubMenuHovering1(true);
  const notHoveringSubMenu1 = () => setSubMenuHovering1(false);

  const [subMenuHovering2, setSubMenuHovering2] = useState(false);
  const isHoveringSubMenu2 = () => setSubMenuHovering2(true);
  const notHoveringSubMenu2 = () => setSubMenuHovering2(false);

  const [subMenuHovering3, setSubMenuHovering3] = useState(false);
  const isHoveringSubMenu3 = () => setSubMenuHovering3(true);
  const notHoveringSubMenu3 = () => setSubMenuHovering3(false);

  // handle click of navigation items
  const clickHandler = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  // close offcanvas onclick outside
  const node = useRef();
  useOnClickOutside(node, () => setOffcanvasOpen(false));

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "global/Logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 192, placeholder: NONE)
        }
      }
    }
  `);

  // Define logos based on header style
  let className = null;

  if (headerStyle === "overlap" || headerStyle === "overlap-hero") {
    //   initialLogo = data.lightLogo.childImageSharp.gatsbyImageData;
    //   stickyLogo = data.darkLogo.childImageSharp.gatsbyImageData;
    className = "absolute";
  } else {
    //   initialLogo = data.darkLogo.childImageSharp.gatsbyImageData;
    //   stickyLogo = data.darkLogo.childImageSharp.gatsbyImageData;
  }

  // if (offcanvasOpen) {
  //   initialLogo = data.darkLogo.childImageSharp.gatsbyImageData;
  //   stickyLogo = data.darkLogo.childImageSharp.gatsbyImageData;
  // }

  const navigation = {
    practiceAreas: [
      {
        name: "Business",
        href: "/business/",
      },
      {
        name: "Construction",
        href: "/construction/",
      },
  //    {
  //      name: "Criminal Defense",
  //      href: "/criminal-defense/",
  //   },
  //    {
  //      name: "DUI / DWAI",
  //      href: "/dui-dwai/",
  //    },
      {
        name: "Elder Law",
        href: "/elder-law/",
      },
      {
        name: "Estate Planning",
        href: "/estate-planning/",
      },
      {
        name: "Family Law",
        href: "/family-law/",
      },
      {
        name: "Guardianships & Conservatorships",
        href: "/guardianships-conservatorships/",
      },
      {
        name: "Medicaid",
        href: "/long-term-elder-care-planning/",
      },
      {
        name: "Personal Injury",
        href: "/personal-injury/",
      },
      {
        name: "Probate",
        href: "/probate/",
      },
      {
        name: "Real Estate",
        href: "/real-estate/",
      },
    ],
    locations: [
      {
        name: "Denver",
        href: "/denver/",
      },
      {
        name: "Broomfield",
        href: "/broomfield/",
      },
      {
        name: "Longmont",
        href: "/longmont/",
      },
      {
        name: "Loveland",
        href: "/loveland/",
      },
    ],
    newsInsights: [
      {
        name: "Blog",
        href: "/blog/",
      },
      {
        name: "Video Library",
        href: "/video-center/",
      },
      {
        name: "Podcast",
        href: "/podcast/",
      },
    ],
  };

  return (
    <nav
      id="main-navigation"
      className={`py-3 lg:py-2 bg-black w-full transition duration-300 ease-linear ${
        headerStyle === "overlap" ? "lg:bg-black/[16%]" : "lg:bg-black"
      } ${
        headerHasBorder && "lg:border-b lg:border-solid lg:border-gray-300"
      } ${offcanvasOpen ? "" : ""} ${
        scrolled && "!fixed !bg-black top-0 left-0 w-full !shadow-5xl z-50"
      } ${className}`}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container flex justify-between items-center">
        <div className="flex-auto flex lg:hidden">
          <a href="tel:720-491-3117">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.58124 0.769072L3.83117 0.0190875C4.18429 -0.0621608 4.54678 0.12221 4.69053 0.453453L6.1905 3.95338C6.32175 4.25962 6.23425 4.61899 5.97488 4.82836L4.08117 6.37833C5.20615 8.77515 7.17173 10.7689 9.61855 11.9157L11.1685 10.022C11.381 9.76263 11.7373 9.67513 12.0435 9.80638L15.5434 11.3063C15.8778 11.4532 16.0622 11.8157 15.9809 12.1688L15.2309 15.4188C15.1528 15.7563 14.8528 16 14.4997 16C6.49674 16 0 9.51576 0 1.50031C0 1.15031 0.240622 0.847195 0.58124 0.769072Z"
                className="fill-current text-primary-600 hover:text-primary-600 transition-colors duration-300 ease-linear"
              />
            </svg>
          </a>
        </div>
        <div className="flex-auto flex justify-center lg:justify-start">
          <AniLink fade to="/">
            <GatsbyImage
              image={data.logo.childImageSharp.gatsbyImageData}
              alt="JBP Legal Logo"
              className="w-[151px] md:w-[192px]"
            />
          </AniLink>
        </div>
        <div className="flex items-center justify-end flex-auto">
          <ul
            id="navigation-desktop"
            className="hidden lg:flex lg:flex-row lg:space-x-8 lg:items-center lg:justify-end lg:mr-20"
          >
            <li
              className="relative"
              role="presentation"
              onMouseEnter={isHoveringSubMenu1}
              onMouseLeave={notHoveringSubMenu1}
            >
              <AniLink
                fade
                to="/practice-areas/"
                className={`font-body hover:text-primary-600 relative text-sm font-normal pb-8 ${
                  subMenuHovering1 ? "text-primary-600" : "text-secondary-50"
                }`}
              >
                Practice Areas
              </AniLink>
              <ul
                className={`absolute top-0 bg-black shadow-3xl w-auto transform -translate-x-8 p-6 space-y-2 z-10 transition-all duration-300 ease-linear ${
                  subMenuHovering1
                    ? " visible translate-y-[44px] opacity-100"
                    : "invisible translate-y-20 opacity-0"
                }`}
              >
                {navigation.practiceAreas.map((item) => (
                  <li key={item.name} className="whitespace-nowrap">
                    <AniLink
                      fade
                      to={item.href}
                      className="text-sm font-normal text-secondary-50 hover:text-primary-600"
                    >
                      {item.name}
                    </AniLink>
                  </li>
                ))}
              </ul>
            </li>

            <li className="group">
              <AniLink
                fade
                to="/our-team/"
                className={`font-body text-secondary-50 hover:text-primary-600 relative text-sm font-normal pb-8 after:absolute`}
              >
                Our Firm
              </AniLink>
            </li>

            <li className="group">
              <AniLink
                fade
                to="/testimonials/"
                className={`font-body text-secondary-50 hover:text-primary-600 relative text-sm font-normal pb-8 after:absolute`}
              >
                Reviews
              </AniLink>
            </li>

            <li
              className="relative"
              role="presentation"
              onMouseEnter={isHoveringSubMenu2}
              onMouseLeave={notHoveringSubMenu2}
            >
              <AniLink
                fade
                to="/locations/"
                className={`font-body hover:text-primary-600 relative text-sm font-normal pb-8 ${
                  subMenuHovering2 ? "text-primary-600" : "text-secondary-50"
                }`}
              >
                Locations
              </AniLink>
              <ul
                className={`absolute top-0 bg-black shadow-3xl flex flex-col space-y-2 p-6 transform -translate-x-8 z-10 transition-all duration-300 ease-linear ${
                  subMenuHovering2
                    ? " visible translate-y-[44px] opacity-100"
                    : "invisible translate-y-20 opacity-0"
                }`}
              >
                {navigation.locations.map((item) => (
                  <li key={item.name} className="whitespace-nowrap">
                    <AniLink
                      fade
                      to={item.href}
                      className="text-sm font-normal text-secondary-50 hover:text-primary-600"
                    >
                      {item.name}
                    </AniLink>
                  </li>
                ))}
              </ul>
            </li>

            <li
              className="relative"
              role="presentation"
              onMouseEnter={isHoveringSubMenu3}
              onMouseLeave={notHoveringSubMenu3}
            >
              <AniLink
                fade
                to="/blog/"
                className={`font-body hover:text-primary-600 relative text-sm font-normal pb-8 ${
                  subMenuHovering3 ? "text-primary-600" : "text-secondary-50"
                }`}
              >
                News & Insights
              </AniLink>
              <ul
                className={`absolute top-0 bg-black shadow-3xl flex flex-col space-y-2 p-6 transform -translate-x-8 z-10 transition-all duration-300 ease-linear ${
                  subMenuHovering3
                    ? " visible translate-y-[44px] opacity-100"
                    : "invisible translate-y-20 opacity-0"
                }`}
              >
                {navigation.newsInsights.map((item) => (
                  <li key={item.name} className="whitespace-nowrap">
                    <AniLink
                      fade
                      to={item.href}
                      className="text-sm font-normal text-secondary-50 hover:text-primary-600"
                    >
                      {item.name}
                    </AniLink>
                  </li>
                ))}
              </ul>
            </li>
          </ul>

          <div className="hidden lg:inline-flex lg:items-center lg:space-x-4">
            <ButtonWithIcon
              modal="modal-contact"
              text="Contact"
              className="min-w-0"
            />
          </div>

          <div className="lg:hidden" ref={node}>
            {/* Burger */}
            <Burger
              offcanvasOpen={offcanvasOpen}
              setOffcanvasOpen={setOffcanvasOpen}
              headerStyle={headerStyle}
              scrolled={scrolled}
              aria-controls="offcanvas-navigation"
            />

            {/* Mobile Nav */}
            <OffCanvas offcanvasOpen={offcanvasOpen} id="offcanvas-navigation">
              <div>
                <ul id="navigation-mobile" className="mb-8 px-4 top-[40px]">
                  <Accordion
                    allowZeroExpanded={true}
                    className="flex flex-col space-y-6"
                  >
                    <li>
                      <AccordionItem uuid={1}>
                        <AccordionItemButton className="flex items-center focus:outline-none">
                          <p className="font-heading text-lg text-secondary-50 hover:text-primary-600 font-extrabold no-underline mb-0 transition-colors duration-300 ease-linear">
                            Practice Areas
                          </p>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-4">
                          <ul>
                            {navigation.practiceAreas.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <AniLink
                                  fade
                                  to={item.href}
                                  onKeyDown={clickHandler}
                                  onClick={clickHandler}
                                  className="block text-sm font-normal text-secondary-50 hover:text-secondary-50 bg-transparent hover:bg-primary-600 py-1 px-8"
                                >
                                  {item.name}
                                </AniLink>
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>

                    <li>
                      <AniLink
                        fade
                        to="/our-team/"
                        onKeyDown={clickHandler}
                        onClick={clickHandler}
                        className="font-heading text-lg text-secondary-50 hover:text-primary-600 font-extrabold no-underline transition-colors duration-300 ease-linear"
                      >
                        Our Firm
                      </AniLink>
                    </li>

                    <li>
                      <AniLink
                        fade
                        to="/testimonials/"
                        onKeyDown={clickHandler}
                        onClick={clickHandler}
                        className="font-heading text-lg text-secondary-50 hover:text-primary-600 font-extrabold no-underline transition-colors duration-300 ease-linear"
                      >
                        Reviews
                      </AniLink>
                    </li>

                    <li>
                      <AccordionItem uuid={2}>
                        <AccordionItemButton className="flex items-center focus:outline-none">
                          <p className="font-heading text-lg text-secondary-50 hover:text-primary-600 font-extrabold no-underline mb-0 transition-colors duration-300 ease-linear">
                            Locations
                          </p>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-4">
                          <ul>
                            {navigation.locations.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <AniLink
                                  fade
                                  to={item.href}
                                  onKeyDown={clickHandler}
                                  onClick={clickHandler}
                                  className="block text-sm font-normal text-secondary-50 bg-transparent hover:bg-primary-600 py-1 px-8"
                                >
                                  {item.name}
                                </AniLink>
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>

                    <li>
                      <AccordionItem uuid={3}>
                        <AccordionItemButton className="flex items-center focus:outline-none">
                          <p className="font-heading text-lg text-secondary-50 hover:text-primary-600 font-extrabold no-underline mb-0 transition-colors duration-300 ease-linear">
                            News & Insights
                          </p>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-4">
                          <ul>
                            {navigation.newsInsights.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <AniLink
                                  fade
                                  to={item.href}
                                  onKeyDown={clickHandler}
                                  onClick={clickHandler}
                                  className="block text-sm font-normal text-secondary-50 bg-transparent hover:bg-primary-600 py-1 px-8"
                                >
                                  {item.name}
                                </AniLink>
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>
                  </Accordion>
                </ul>

                <ButtonSolid
                  modal="modal-contact"
                  text="Contact"
                  className="w-full"
                />
              </div>
            </OffCanvas>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default MainNav;
